@import "../../config/styles/variables";

.authBox {
  @sidePadding: 50px;
  @sideWidth: 430px;
  @media (max-width: @mobileWidth) {
    width: 100%;
    --border-radius: 0;
    border-radius: 0;
  }
  .authForm, .authLinks {
    @media (max-width: @mobileWidth) {
      width: 100%;
      min-width: unset !important;
      padding: 0 !important;
    }
  }
  .authLinks {
    min-width: @sideWidth;
    padding: 0 16px 0 @sidePadding;
    ion-img {
      width: 250px;
      @media (max-width: @mobileWidth) {
        width: 200px;
      }
    }
  }
  .authForm {
    min-width: @sideWidth;
    max-width: @sideWidth;
    padding: 20px @sidePadding 20px 20px;
    border-right: 1px solid var(--ion-color-medium);
    @media (max-width: @mobileWidth) {
      border-right: none;
      margin: 0 auto;
    }
  }
}