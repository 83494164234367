@import "../../../../config/styles/variables";
@import "./chargeModal";

.selectionModal:extend(.chargeModal all) {
  .card {
    .subWrap {
      flex: 1;

      ion-item {
        flex: 1;
        min-width: 250px;
      }
    }

    ion-card-header {
      padding: 8px 16px;
      @media (max-width: @mobileWidthIntermediate) {
        flex-direction: column;
        align-items: flex-start !important;
        * { flex: 1 }
        ion-card-title {
          margin: 8px 0;
        }
        ion-button {
          min-height: 36px;
        }
      }
    }

    ion-buttons {
      width: 100%;
    }

    .deSelectText {
      ion-icon {
        vertical-align: top;
      }
    }
  }
}