@import "./variables";

.genericTable {
  .MuiTableContainer-root {
    max-height: 100%;
  }
  .MuiTableHead-root {
    background-color: var(--ion-color-light);
    border-bottom: 2px solid @itemBorderColor;
  }
  .MuiTableRow-root {
    border-bottom-color: @itemBorderColor !important;
  }
  .MuiTableCell-stickyHeader {
    z-index: 10;
  }
  .MuiTableCell-root {
    padding: 0 5px 0 16px;
    border-right: 1px solid @itemBorderColor;
    &.MuiTableCell-head {
      padding-left: 8px;
      &.noSort {
        padding: 0;
      }
    }
  }
  .MuiInputBase-root {
    font-size: @fontSizeXS !important;
  }
}