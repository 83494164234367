.chargeModal {
  .listControlToolbar {
    flex: unset;
    margin: 0 auto 8px;
    max-width: 600px;
    width: 100%;
  }
  .carrierInfo {
    padding: 0 16px;
    ion-button, ion-text {
      margin: 10px 0 8px;
    }
  }
}