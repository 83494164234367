@import "../../../../config/styles/variables";
@import "../../../../config/styles/container";

.rfqDetail:extend(.cardedContainer all) {
  .rfqDetailColumnsContainer:extend(.cardedContainer .cardedColumnsContainer all) {
    .rfqDetailColumns {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      padding: 16px 8px;
      @media (max-width: @mobileWidth) {
        width: unset;
      }

      .baseColumn {
        flex: 1;
        margin: 0 8px;

        @media (max-width: @mobileWidth) {
          min-width: 325px;
          max-width: 325px;
        }

        .accordion {
          .quotationServiceCard {
            margin: 0 !important;

            &:not(:last-child) {
              ion-card {
                margin-bottom: 8px !important;
              }
            }

            ion-card {
              ion-card-content {
                padding: 14px;
              }
              @media (max-width: @mobileWidth) {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}