@defaultBackground: linear-gradient(180deg, rgb(61, 97, 155) 0%, rgb(94, 128, 190) 22%, #ffffff 100%);

@mobileWidthXS: 350px;
@mobileWidth: 768px;
@mobileWidthIntermediate: 960px;
@viewMaxWidth: 1366px;
@viewMaxWidthXW: 1600px;

@fontSizeXXS: 13px;
@fontSizeXS: 14px;
@fontSizeS: 16px;
@fontSizeM: 19px;
@fontSizeL: 25px;
@fontSizeXL: 30px;
@fontSizeXXL: 35px;

@itemBorderColor: var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0,0,0,0.13)) ) );

@elevated: {
  border-radius: 4px;
  box-shadow: 0 2px 5px @itemBorderColor;
}