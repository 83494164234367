@import "../../../../config/styles/variables";

.rateCard {
  @media (max-width: @mobileWidthIntermediate) {
    ion-card-title {
      font-size: @fontSizeM;
    }
    .editDescriptionIcon {
      font-size: @fontSizeM - 1;
    }
  }

  .accordion {
    &:not(.expanded) {
      ion-card-header {
        border-bottom: 1px solid var(--ion-color-primary);
      }
    }
    .accordionContent {
      @elevated();
      border-radius: 0;
    }
  }

  .itemGroups {
    @elevated();
    background-color: #fff;
  }

  .firstGroup {
    .firstGroupSubWrap {
      flex: 1;

      ion-item {
        flex: 1;
        min-width: 200px;
        max-height: 64px;

        &:last-child {
          min-width: 225px;
        }
      }

      ion-datetime {
        font-size: @fontSizeXS;
      }

      ion-input {
        --placeholder-color: var(--ion-color-primary);
        --placeholder-opacity: 1;
        input::placeholder {
          text-decoration: underline;
        }
      }

      .ion-activatable {
        width: 100%;
      }
    }
  }

  .secondGroup {
    .rateChargeItems {
      * {
        font-size: @fontSizeXS;
      }
    }
  }

  .thirdGroup {
    .rateUnitItem {
      @elevated();
      &:not(:last-child) {
        margin-bottom: 16px;
      }

      .rateUnitInfo {
        .thirdGroupSubWrap {
          flex: 1;

          ion-item {
            flex: 1;
            min-width: 160px;
          }
          ion-input, ion-datetime, ion-select {
            font-size: @fontSizeXS;
            @media (max-width: @mobileWidth) {
              font-size: @fontSizeXXS;
            }
          }
          ion-datetime {
            --padding-top: 8px;
            --padding-bottom: 8px;
          }
        }
        .inverseWrapMobile400 {
          @media (max-width: 400px) {
            flex-wrap: wrap;
          }
          flex-wrap: wrap-reverse;
        }
      }
      .rateChargeItems {
        * {
          font-size: @fontSizeXS;
        }
      }

      .footerBar {
        background-color: #fcfcfc;
        box-shadow: inset 0 10px 8px -8px @itemBorderColor;
      }
    }

    .addChargeButton {
      padding-left: 5px;
    }
  }
}

.rateChargeModal {}
