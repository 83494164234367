@import "../../../../../config/styles/variables";

.legReorder {
  flex: 1;
  max-width: 674px;
  border: 1px solid @itemBorderColor;
  border-right: none;

  &.readonly {
    border-left: none !important;
  }

  ion-item-divider {
    border: none;
  }
}

.leg {
  flex: 1;
  width: 100%;

  .half {
    width: 100%;
    display: flex;
    @media (max-width: @mobileWidth) {
      flex-direction: column;
      ion-item {
        width: 100%;
        border-left: 1px solid @itemBorderColor;
      }
    }
    div, ion-item {
      flex: 1;
    }
    ion-item:first-child {
      border-left: 1px solid @itemBorderColor !important;
    }
    ion-item {
      border-right: 1px solid @itemBorderColor !important;
    }
  }

  .polPodLetItem {
    --inner-padding-end: 0;
  }
}