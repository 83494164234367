@import "./variables";
@import "./global";

.container {
  max-width: @viewMaxWidth;
  margin: 0 auto;
}
.extra-wide { max-width: @viewMaxWidthXW; }

.cardedContainer {
  &.max {
    min-height: 100%;
  }

  @media (max-width: @mobileWidth) {
    --border-radius: 0;
    border-radius: 0;
  }

  .cardedColumnsContainer {
    background: var(--ion-color-light);
    position: relative;
    overflow-y: hidden;
    flex: 1;
    @media (max-width: @mobileWidth) {
      overflow-x: auto;
      white-space: nowrap;
    }
  }

  .cardedContainerHeader {
    .cardedContainerSubHeader:extend(.textDarkMedium) {
      display: flex;
      align-items: center;
      margin-top: 8px;
      margin-left: 12px;
    }
  }
}