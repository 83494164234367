@import "../../../../config/styles/variables";
@import "../../../../config/styles/global";

.rfqForm {

  ion-item {
    --inner-padding-end: 0;
    --padding-end: 0;
  }

  .heavyDivider {
    min-height: 3px;
    max-height: 3px;
    * {
      display: none;
    }
  }

  .borderTop {
    border-top: 1px solid @itemBorderColor;
  }

  .half {
    flex: 1;
    display: flex;
    @media (max-width: @mobileWidth) {
      flex-direction: column;
      ion-item {
        width: 100%;
        border-right: none !important;
      }
    }
    div, ion-item {
      flex: 1;
    }
    ion-item:not(:last-child) {
      border-right: 1px solid @itemBorderColor;
    }
    ion-datetime {
      font-size: @fontSizeXS - 1;
    }
  }

  .buttonRow {
    &:not(.checkboxRow) {
      padding-bottom: 8px;
    }
    @media (max-width: @mobileWidth) {
      flex-wrap: wrap;
      &:not(:nth-child(2)) {
        padding-top: 0;
      }
      ion-button {
        margin: 8px 0;
      }
    }
    .checkboxItem {
      min-width: 160px;
    }
    ion-button {
      color: var(--ion-color-primary) !important;
      min-width: 120px;
      span {
        margin-left: 5px;
        width: 100%;
        text-align: left;
        font-size: @fontSizeS !important;
        text-transform: initial !important;
      }
    }
  }

  .service {
    @media (max-width: @mobileWidth) {
      ion-item:first-child {
        border-bottom: 1px solid @itemBorderColor;
      }
      ion-item {
        border-left: 1px solid @itemBorderColor;
        border-right: 1px solid @itemBorderColor !important;
      }
    }
    border-bottom: 1px solid @itemBorderColor;

    ion-reorder, ion-item:first-child {
      border-left: 1px solid @itemBorderColor;
    }
    ion-item:last-child {
      border-right: 1px solid @itemBorderColor;
    }
    .serviceItem {
      flex: 1;
    }
  }

  .freightHeader {
    width: 100%;
    min-height: 64px;
    ion-buttons {
      flex: 1;
    }
    ion-buttons > span {
      min-width: 90px;
      @media (max-width: @mobileWidth) {
        min-width: 55px;
      }
    }
    ion-button {
      margin-left: 5px !important;
    }
    ion-item {
      --background: #ffffff00 !important;
    }
    .freightHeaderInfo {
      flex: 1;
      animation-name: show;
      animation-duration: .35s;
      animation-timing-function: ease;
      animation-fill-mode: forwards;
      @keyframes show {
        from {transform: translateY(70px);}
        to {transform: translateY(0);}
      }
      ion-item {
        flex: 1;
        .collapsedPodPol {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .caret {
      min-width: 20px;
      margin-left: 8px;
    }
  }

  .topBorder {
    border-top: 1px solid @itemBorderColor;
  }

  .helpTip {
    top: 5px;
    right: 5px;
    z-index: 999;
  }
}